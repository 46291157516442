// Adds a border between each cell
// How to unhack: Move to Primer CSS
// When moving, also replace .discussion-sidebar-item
.BorderGrid {
  display: table;
  width: 100%;
  margin-top: calc(var(--base-size-16) * -1);
  margin-bottom: calc(var(--base-size-16) * -1);
  table-layout: fixed;
  border-collapse: collapse;
  // stylelint-disable-next-line primer/borders
  border-style: hidden;

  .BorderGrid-cell {
    padding-top: var(--base-size-16);
    padding-bottom: var(--base-size-16);
  }
}

.BorderGrid--spacious {
  margin-top: calc(var(--base-size-24) * -1);
  margin-bottom: calc(var(--base-size-24) * -1);

  .BorderGrid-cell {
    padding-top: var(--base-size-24);
    padding-bottom: var(--base-size-24);
  }
}

.BorderGrid-row {
  display: table-row;
}

.BorderGrid-cell {
  display: table-cell;
  border: $border-width $border-style var(--borderColor-muted, var(--color-border-muted));
}
